<template>
  <div>
    <v-row id="dashboardLists">
      <v-col v-if="recruiter.access !== 'expert'" cols="12" md="4">
        <h2 v-t="'draft'" class="text-xl font-weight-semibold" />
        <v-list color="transparent">
          <draggable
            id="incoming"
            :list="draft"
            group="people"
            v-bind="dragOptions"
            :move="onMoveIncoming"
            :scroll-sensitivity="350"
            :force-fallback="true"
            @end="isDraggingEnd"
            @change="handleChange($event, draft)"
            @start="onDragStart"
          >
            <v-card
              v-for="(job, index) in draft"
              :key="index"
              class="my-2"
              :to="{ name: 'apps-ats-job-view', params: { id: job.id } }"
            >
              <v-card-title class="d-flex align-center flex column flex-nowrap justify-space-between">
                <div>{{ job.title }}</div>
                <v-avatar color="secondary v-avatar-light-bg secondary--text" size="32" class="align-self-start">
                  <span class="font-weight-medium">{{
                    avatarText(`${job.assignedTo.user.firstName} ${job.assignedTo.user.lastName}`)
                  }}</span>
                </v-avatar>
              </v-card-title>
              <v-card-subtitle v-if="$can(ACTIONS.UPDATE, PRIVATE.ATS.BOARD_CARD)"
                ><div>{{ job.city }}{{ job.city || job.country ? ', ' : '' }}{{ job.country }}</div>
                <v-icon class="cursor-pointer my-handle" color="primary">{{ icons.mdiDrag }}</v-icon></v-card-subtitle
              >
            </v-card>
          </draggable>
        </v-list>
      </v-col>

      <v-col cols="12" md="4">
        <h2 v-t="'ongoing'" class="text-xl font-weight-semibold" />
        <v-list color="transparent">
          <draggable
            id="ongoing"
            :list="ongoing"
            group="people"
            v-bind="dragOptions"
            :move="onMoveOngoing"
            :scroll-sensitivity="350"
            :force-fallback="true"
            @change="handleChange($event, ongoing)"
            @start="onDragStart"
            @end="isDraggingEnd"
          >
            <div v-for="(job, index) in ongoing" :key="index">
              <v-badge
                v-if="job.newCandidatesAmount > 0"
                overlap
                color="error"
                class="w-full"
                offset-x="12"
                offset-y="16"
              >
                <template v-slot:badge>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" class="font-weight-semibold" v-on="on">{{ job.newCandidatesAmount }}</span>
                    </template>
                    <span>{{ $t('newCandidatesAmount', { amount: job.newCandidatesAmount }) }}</span>
                  </v-tooltip>
                </template>
                <v-card class="my-2" :to="{ name: 'apps-ats-job-view', params: { id: job.id } }">
                  <v-card-title class="d-flex align-center flex column flex-nowrap justify-space-between">
                    <div>{{ job.title }}</div>
                    <v-avatar color="primary v-avatar-light-bg primary--text" size="32" class="align-self-start">
                      <span class="font-weight-medium">{{
                        avatarText(`${job.assignedTo.user.firstName} ${job.assignedTo.user.lastName}`)
                      }}</span>
                    </v-avatar>
                  </v-card-title>
                  <v-card-subtitle v-if="$can(ACTIONS.UPDATE, PRIVATE.ATS.BOARD_CARD)"
                    ><div>{{ job.city }}{{ job.city || job.country ? ', ' : '' }}{{ job.country }}</div>
                    <v-icon class="cursor-pointer my-handle" color="primary">{{
                      icons.mdiDrag
                    }}</v-icon></v-card-subtitle
                  >
                </v-card>
              </v-badge>
              <v-card v-else class="my-2" :to="{ name: 'apps-ats-job-view', params: { id: job.id } }">
                <v-card-title class="d-flex align-center flex column flex-nowrap justify-space-between">
                  <div>{{ job.title }}</div>
                  <v-avatar color="primary v-avatar-light-bg primary--text" size="32" class="align-self-start">
                    <span class="font-weight-medium">{{
                      avatarText(`${job.assignedTo.user.firstName} ${job.assignedTo.user.lastName}`)
                    }}</span>
                  </v-avatar>
                </v-card-title>
                <v-card-subtitle v-if="$can(ACTIONS.UPDATE, PRIVATE.ATS.BOARD_CARD)"
                  ><div>{{ job.city }}{{ job.city || job.country ? ', ' : '' }}{{ job.country }}</div>
                  <v-icon class="cursor-pointer my-handle" color="primary">{{ icons.mdiDrag }}</v-icon></v-card-subtitle
                >
              </v-card>
            </div>
          </draggable>
        </v-list>
      </v-col>

      <v-col cols="12" md="4">
        <h2 v-t="'finished'" class="text-xl font-weight-semibold" />
        <!--        <v-list color="transparent">-->
        <draggable
          id="finished"
          v-model="finished"
          v-bind="dragOptions"
          :scroll-sensitivity="350"
          :force-fallback="true"
          :disabled="false"
          @start="onDragStart"
          @end="isDraggingEnd"
        >
          <div v-for="(job, index) in finished" :key="index" class="my-2">
            <v-card class="outlined my-handle">
              <v-card-title class="d-flex align-center flex column flex-nowrap justify-space-between">
                <div>{{ job.title }}</div>
                <v-avatar color="accent v-avatar-light-bg accent--text" size="32" class="align-self-start">
                  <span class="font-weight-medium">{{
                    avatarText(`${job.assignedTo.user.firstName} ${job.assignedTo.user.lastName}`)
                  }}</span>
                </v-avatar>
              </v-card-title>
              <v-card-subtitle
                >{{ job.city }}{{ job.city || job.country ? ', ' : '' }}{{ job.country }}</v-card-subtitle
              >
            </v-card>
          </div>
        </draggable>
        <!--        </v-list>-->
      </v-col>
    </v-row>
    <v-dialog
      v-model="showConfirmationModal"
      persistent
      :max-width="moveToFinish ? '850px' : '650px'"
      overlay-color="#7f7f7f"
      overlay-opacity="0.2"
    >
      <v-card v-if="!moveToFinish" class="pa-sm-10 pa-3">
        <v-card-title v-t="'confirm'" class="justify-center text-h5" />
        <v-card-text>
          <v-row>
            <v-col cols="12" class="d-flex align-center justify-center text-center">{{ $t('moveToOngoing') }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn v-t="'discard'" outlined class="me-3" @click="revertChange" />
              <v-btn color="primary" :loading="changeConfirmationBtnLoading" @click="confirmChange">
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else class="pa-sm-10 pa-3">
        <v-card-title v-t="'confirm'" class="justify-center text-h5" />
        <v-card-text>
          <v-row>
            <v-col cols="12" class="ma-auto text-center">
              <div>{{ $t('moveToFinished') }}</div>
              <div>{{ $t('confirmationModalUndoneQuestion') }}</div>
              <div v-if="!selectedEmail">{{ $t('confirmationModalDefaultEmail') }}</div>
              <div v-else>{{ $t('confirmationModalDefaultEmailConditions') }}</div>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selectedEmail"
                :label="$t('selectEmail')"
                :placeholder="$t('selectEmail')"
                :items="emailTemplates"
                outlined
                dense
                hide-details="auto"
                @change="setEmailTemplateData"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selectedRejectReason"
                :label="$t('selectRejectReason')"
                :placeholder="$t('selectRejectReason')"
                :items="rejectReasonChoices"
                item-value="id"
                item-text="rejectReason"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field
                v-model="cc"
                :label="$t('cc')"
                :placeholder="$t('cc')"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :hint="$t('separateByComma')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5">
              <v-switch v-model="sendCopyToRecruiter" class="mt-0" inset color="primary">
                <template v-slot:label> {{ $t('sendMeCopy') }} </template>
              </v-switch>
            </v-col>
          </v-row>
          <v-form v-if="selectedEmail" ref="emailForm" v-model="valid">
            <v-row>
              <v-col cols="12" sm="7">
                <v-text-field
                  v-model="selectedEmailTemplateSubject"
                  :label="$t('subject')"
                  :placeholder="$t('subject')"
                  outlined
                  dense
                  maxlength="100"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-select
                  v-model="selectedEmailTemplateLang"
                  outlined
                  dense
                  :items="[
                    {
                      text: $t('pl'),
                      value: 'pl',
                    },
                    {
                      text: $t('eng'),
                      value: 'eng',
                    },
                  ]"
                  :label="$t('language')"
                  :placeholder="$t('language')"
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <summer-note
                  :config="{ height: 300 }"
                  hide-link-and-image-button
                  @ready="readySummerNote"
                ></summer-note>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn v-t="'discard'" outlined class="me-3" @click="revertChange" />
              <v-btn color="primary" :loading="changeConfirmationBtnLoading" @click="confirmChange">
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { ref, computed, getCurrentInstance, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { mdiDrag } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import SummerNote from '@/plugins/summer-note/SummerNote.vue'
import useDraggableOptions from '@/plugins/draggable'
import { EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE } from '@/plugins/summer-note/variables'
import { PRIVATE, ACTIONS } from '@/plugins/acl/const'

export default {
  name: 'Dashboard',
  components: {
    draggable,
    SummerNote,
  },
  setup() {
    const { recruiter } = useState('recruiter', ['recruiter'])
    const { getMyJobs, rejectAllCandidates, changeJobStage, getEmailTemplatesChoices, getRejectReasonsChoices } =
      useActions('jobs', [
        'getMyJobs',
        'rejectAllCandidates',
        'changeJobStage',
        'getEmailTemplatesChoices',
        'getRejectReasonsChoices',
      ])

    const draft = ref([])
    const ongoing = ref([])
    const finished = ref([])

    const offersAreNotEmpty = computed(() => {
      return !!draft.value.length || !!ongoing.value.length || !!finished.value.length
    })

    // editable: true, // ToDo: Fix moving when job details are opened bitch!
    // isDragging: false,
    const valid = ref(false)
    const delayedDragging = ref(false)
    const payload = ref({})
    const draggedElementId = ref(null)
    const changes = ref([])
    const moveToFinish = ref(false)
    const showConfirmationModal = ref(false)
    const changeConfirmationBtnLoading = ref(false)
    const emailTemplates = ref([])
    const selectedEmail = ref(null)
    const selectedEmailTemplateHtml = ref('')
    const selectedEmailTemplateSubject = ref('')
    const selectedEmailTemplateLang = ref('')
    const cc = ref('')
    const sendCopyToRecruiter = ref(false)
    const rejectReasonChoices = ref([])
    const selectedRejectReason = ref(null)

    // const { dragOptions, onDragStart, onDragEnd } = useDraggableOptions()
    const { dragOptions, onDragStart, onDragEnd } = useDraggableOptions()

    const editor = ref(null)
    const emailVariablesSummernote = ref([])
    const vm = getCurrentInstance().proxy
    emailVariablesSummernote.value = EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE(vm)
    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', selectedEmailTemplateHtml.value)
      editor.value.$on('change', (content) => {
        selectedEmailTemplateHtml.value = content
      })
    }

    const fetchMyJobs = async () => {
      const jobs = await getMyJobs()
      draft.value = jobs.filter((job) => job.stage === 'draft')
      ongoing.value = jobs.filter((job) => job.stage === 'ongoing')
      finished.value = jobs.filter((job) => job.stage === 'finished')
    }
    onMounted(async () => {
      await fetchMyJobs()
    })
    const onMoveIncoming = (event) => {
      if (event.to.id === 'incoming' && event.from.id === 'incoming') return false
      if (event.to.id === 'incoming' && event.from.id !== 'incoming') return false
      draggedElementId.value = event.draggedContext.element.id
      moveToFinish.value = event.to.id === 'finished'

      return true
    }
    const onMoveOngoing = (event) => {
      if (event.to.id === 'incoming') return false
      if (event.from.id === 'finished') return false
      if (event.from.id === 'ongoing' && event.to.id === 'ongoing') return false
      if (recruiter.value.access !== 'admin' && event.draggedContext.element.assignedTo.id !== recruiter.value.id)
        return false
      draggedElementId.value = event.draggedContext.element.id
      moveToFinish.value = event.to.id === 'finished'

      return true
    }
    const onMoveFinished = () => {
      return false
    }
    const handleChange = async (event, elements) => {
      changes.value.push({
        event,
        elements,
      })
      if (moveToFinish.value) {
        rejectReasonChoices.value = await getRejectReasonsChoices()
        emailTemplates.value = await getEmailTemplatesChoices({
          actionType: 'rejected',
        })
      }
      showConfirmationModal.value = true
    }
    const revertChange = () => {
      changes.value.forEach(({ event, elements }) => {
        if (event.added) {
          const { newIndex } = event.added
          elements.splice(newIndex, 1)
        }

        if (event.removed) {
          const { oldIndex, element } = event.removed
          elements.splice(oldIndex, 0, element)
        }

        if (event.moved) {
          const { newIndex, oldIndex, element } = event.moved
          // eslint-disable-next-line no-param-reassign
          elements[newIndex] = elements[oldIndex]
          // eslint-disable-next-line no-param-reassign
          elements[oldIndex] = element
        }
      })
      changes.value = []
      showConfirmationModal.value = false
      rejectReasonChoices.value = []
      emailTemplates.value = []

      // setDefault()
    }

    const confirmChange = async () => {
      changes.value = []
      changeConfirmationBtnLoading.value = true
      if (moveToFinish.value) {
        await rejectAllCandidates({
          jobId: draggedElementId.value,
          bodyHtml: selectedEmailTemplateHtml.value,
          subject: selectedEmailTemplateSubject.value,
          lang: selectedEmailTemplateLang.value,
          cc: cc.value,
          sendCopyToRecruiter: sendCopyToRecruiter.value,
          rejectReason: selectedRejectReason.value,
        })
      }
      await changeJobStage({ ...payload.value })
      showConfirmationModal.value = false
      changeConfirmationBtnLoading.value = false
      draggedElementId.value = null

      // setDefault()
    }
    const isDraggingEnd = async (event) => {
      event.target.classList.remove('grabbing')
      const nextStage = event.to.id
      payload.value = {
        jobId: draggedElementId.value,
        stage: nextStage,
      }
    }
    const dragStart = () => {
      const grid = document.getElementById('workflowPageGrid')
      grid.classList.remove('Dashboard__div--lists-scroll')
      grid.classList.add('Dashboard__div--lists-scroll-disabled')
    }
    const dragEnd = () => {
      const grid = document.getElementById('workflowPageGrid')
      grid.classList.remove('Dashboard__div--lists-scroll-disabled')
      grid.classList.add('Dashboard__div--lists-scroll')
    }
    const setEmailTemplateData = () => {
      const emailTemplate = emailTemplates.value.find((et) => et.value === selectedEmail.value)
      selectedEmailTemplateHtml.value = emailTemplate?.bodyHtml
      selectedEmailTemplateSubject.value = emailTemplate?.subject
      selectedEmailTemplateLang.value = emailTemplate?.lang

      // this.editor.summernote('code', this.selectedEmailTemplateHtml)
    }
    const setDefault = () => {
      selectedEmailTemplateHtml.value = ''
      selectedEmailTemplateSubject.value = ''
      selectedEmailTemplateLang.value = ''
      cc.value = ''
      sendCopyToRecruiter.value = false
      selectedEmail.value = null
      editor.summernote('code'.value, '')

      // $refs.emailForm?.reset()
    }

    return {
      handleChange,
      setEmailTemplateData,
      isDraggingEnd,
      confirmChange,
      onMoveIncoming,
      dragStart,
      dragEnd,
      onMoveFinished,
      onMoveOngoing,
      revertChange,
      avatarText,
      onDragStart,
      onDragEnd,

      selectedRejectReason,

      readySummerNote,

      offersAreNotEmpty,
      recruiter,

      valid,

      draft,
      ongoing,
      finished,
      delayedDragging,
      payload,
      draggedElementId,
      changes,
      moveToFinish,
      showConfirmationModal,
      changeConfirmationBtnLoading,
      emailTemplates,
      selectedEmail,
      selectedEmailTemplateHtml,
      selectedEmailTemplateSubject,
      selectedEmailTemplateLang,
      cc,
      sendCopyToRecruiter,
      rejectReasonChoices,

      dragOptions,
      icons: {
        mdiDrag,
      },

      PRIVATE,
      ACTIONS,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}
.Dashboard {
  &__draggable {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
//.ghost {
//  opacity: 0;
//  background: #faf8f5;
//}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
